<template>
  <a-tabs type="card" class="sm-mt-20" @change="callback">
    <a-tab-pane key="1" tab="基本信息">
      <a-form layout="inline">
        <a-form-item>
          <a-button type="primary" class="new" @click="handleShow">
            预览
          </a-button>
        </a-form-item>
      </a-form>
      <a-row>
        <a-col :span="7"> </a-col>
        <a-col :span="9">
          <a-spin :spinning="loading">
            <a-form-model
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-form-model-item label="软件名称" prop="name">
                <a-input
                  v-model="ruleForm.name"
                  type="text"
                  autocomplete="off"
                  placeholder="请输入软件名称"
                />
              </a-form-model-item>
              <a-form-model-item label="简要描述" prop="description">
                <a-input
                  v-model="ruleForm.description"
                  type="textarea"
                  autocomplete="off"
                  placeholder="请输入软件简要描述"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                />
              </a-form-model-item>
              <a-form-model-item label="软件图标">
                <span class="redFont"
                  >支持图片格式：PNG、JPG、GIF、JPEG，10M以内，推荐图标尺寸64*64px</span
                >
                <a-upload
                  name="icon"
                  list-type="picture-card"
                  class="avatar-uploader"
                  :show-upload-list="false"
                  :before-upload="beforeUpload1"
                >
                  <img
                    v-if="imageUrl1"
                    :src="imageUrl1"
                    class="icon"
                    alt="avatar"
                  />
                  <div v-else>
                    <a-icon :type="loading1 ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">点击上传</div>
                  </div>
                </a-upload>
                <a-upload
                  name="f_icon"
                  list-type="picture-card"
                  class="avatar-uploader"
                  :show-upload-list="false"
                  :before-upload="beforeUpload2"
                >
                  <img
                    v-if="imageUrl2"
                    :src="imageUrl2"
                    class="f_icon"
                    alt="avatar"
                  />
                  <div v-else>
                    <a-icon :type="loading2 ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">点击上传</div>
                  </div>
                </a-upload>
                <div class="clearfix"></div>
                <div class="upload-word">
                  <span>软件图标—正常</span>
                  <span>软件图标—焦点</span>
                </div>
              </a-form-model-item>
              <a-form-model-item label="软件版本号" prop="version">
                <a-input
                  v-model="ruleForm.version"
                  type="text"
                  autocomplete="off"
                  placeholder="请输入版本号"
                />
              </a-form-model-item>
              <a-form-model-item label="软件授权" prop="auth_type">
                <a-select v-model="ruleForm.auth_type" placeholder="请选择软件">
                  <a-select-option value="free"> 免费软件 </a-select-option>
                  <a-select-option value="fee"> 收费软件 </a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- <a-form-model-item label="系统进制" prop="bit">
                <a-select v-model="ruleForm.bit" placeholder="请选择系统进制">
                  <a-select-option value="32">32位</a-select-option>
                  <a-select-option value="64">64位</a-select-option>
                </a-select>
              </a-form-model-item> -->
              <a-form-model-item label="所属分组" prop="main_app_id">
                <a-select
                  v-model="ruleForm.main_app_id"
                  placeholder="请选择软件分组"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  showSearch
                >
                  <a-select-option
                    v-for="(item, index) in groupOptions"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="软件分类"
                prop="first_cate_id"
                class="first_cate"
              >
                <a-select
                  v-model="ruleForm.first_cate_id"
                  placeholder="请选择软件一级分类"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  showSearch
                >
                  <a-select-option
                    v-for="(item, index) in firstCateOptions"
                    :key="index"
                    :value="item.id"
                    @click="handleOption(item.id)"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label
                prop="second_cate_id"
                class="second_cate"
              >
                <a-select
                  v-model="ruleForm.second_cate_id"
                  placeholder="请选择软件二级分类"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  showSearch
                >
                  <a-select-option
                    v-for="(item, index) in secondCateOptions2"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="软件标签" prop="label">
                <a-textarea
                  v-model="ruleForm.label"
                  placeholder="请为软件打上标签，每个标签不超过4个字，标签之间用顿号隔开，便签数量不超过10个"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                />
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
                <a-button type="danger" @click="handleClear" html-type="button">
                  清空设置
                </a-button>
                <a-button
                  :style="{ marginLeft: '20px' }"
                  type="primary"
                  :disabled="unChange"
                  @click="handleSubmit"
                >
                  保存
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </a-spin>
        </a-col>
        <a-col :span="8"> </a-col>
      </a-row>
    </a-tab-pane>
    <a-tab-pane key="2" tab="版本管理">
      <a-form layout="inline">
        <a-form-item>
          <a-button type="primary" @click="handleShow"> 预览 </a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="showModal('new')">
            新增软件包
          </a-button>
        </a-form-item>
      </a-form>
      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: true }"
      >
        <template slot="bit" slot-scope="text"> {{ text }}位 </template>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="handleById(record, 'update')">编辑</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="handleDelete(record)" class="delete"
            >删除</a
          >
        </span>
      </a-table>
      <Pagination
        :total="total"
        @handleChange="handleChange"
        v-show="total > 20"
      />
      <a-modal
        :title="title"
        v-model="visible"
        okText="确认"
        cancelText="取消"
        :maskClosable="false"
        @ok="handleOk"
        @cancel="handleCancel"
        :ok-button-props="{ props: { disabled: isDisabled } }"
      >
        <a-form
          :form="form2"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-item label="操作系统">
            <a-select
              v-decorator="[
                'system',
                { rules: [{ required: true, message: '请选择操作系统' }] },
              ]"
              placeholder="请选择操作系统"
            >
              <a-select-option
                v-for="(item, index) in system"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="系统进制">
            <a-select
              v-decorator="[
                'bit',
                { rules: [{ required: true, message: '请选择进制' }] },
              ]"
              placeholder="请选择进制"
            >
              <a-select-option value="32" key="32"> 32位 </a-select-option>
              <a-select-option value="64" key="64"> 64位 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="软件包">
            <span class="redFont"
              >请上传软件包，上传过程中不要关闭当前窗口</span
            >
            <a-upload
              name="file"
              :multiple="true"
              :file-list="fileList"
              @change="handleFileChange"
              :before-upload="beforeUpload3"
            >
              <a-button>
                <a-icon type="upload" />
                文件上传
              </a-button>
            </a-upload>
            <a-progress :percent="progress" />
          </a-form-item>
          <a-form-item label="上传作者">
            <a-input
              v-decorator="[
                'author',
                { rules: [{ required: true, message: '请输入上传作者!' }] },
              ]"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </a-tab-pane>
    <a-tab-pane key="3" tab="软件介绍" :forceRender="true">
      <a-form layout="inline">
        <a-form-item>
          <a-button
            type="primary"
            class="new"
            @click="handleShow('introduction')"
          >
            预览
          </a-button>
        </a-form-item>
      </a-form>
      <a-row>
        <a-col :span="2"> </a-col>
        <a-col :span="20">
          <a-form :form="form2">
            <a-form-item>
              <div
                class="wangeditor-box"
                ref="editorE1"
                style="text-align: left; min-height: 410px"
              ></div>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="handleIntroSub"> 保存 </a-button>
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :span="2"> </a-col>
      </a-row>
    </a-tab-pane>
    <a-tab-pane key="4" tab="安装说明" :forceRender="true">
      <a-form layout="inline">
        <a-form-item>
          <a-button
            type="primary"
            class="new"
            @click="handleShow('instruction')"
          >
            预览
          </a-button>
        </a-form-item>
      </a-form>
      <a-col :span="2"> </a-col>
      <a-col :span="20">
        <a-form :form="form2">
          <a-form-item>
            <div class="page-editor">
              <div id="wangeditor" class>
                <div
                  class="wangeditor-box"
                  ref="editorE2"
                  style="text-align: left; min-height: 410px"
                ></div>
              </div>
            </div>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="handleExSub"> 保存 </a-button>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="2"> </a-col>
    </a-tab-pane>
    <a-tab-pane key="5" tab="用户评论" :forceRender="true">
      <a-form layout="inline">
        <a-form-item>
          <a-button type="primary" class="new" @click="handleShow('comment')">
            预览
          </a-button>
        </a-form-item>
      </a-form>
      <div v-if="comments.length > 0">
        <a-comment v-for="(item, index) in comments" :key="index">
          <span slot="actions" key="comment-nested-reply-to">
            <a href="javascript:;" @click="handleReply(item)">回复</a>
            <a-divider type="vertical" />
            <a
              href="javascript:;"
              class="delete"
              @click="handleReplyDelete(item.id)"
              >删除</a
            >
          </span>
          <a slot="author"
            >{{ item.user_id }} <span>{{ item.created_at }}</span></a
          >
          <a-avatar
            slot="avatar"
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
            alt="Han Solo"
          />
          <p slot="content">
            {{ item.comment }}
          </p>
          <a-comment v-for="(item, index) in item.children" :key="index">
            <span slot="actions">
              <a
                href="javascript:;"
                class="delete"
                @click="handleReplyDelete(item.id)"
                >删除</a
              >
            </span>
            <a slot="author"
              >{{ item.admin_id }} <span>{{ item.created_at }}</span></a
            >
            <a-avatar
              slot="avatar"
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              alt="Han Solo"
            />
            <p slot="content">
              {{ item.comment }}
            </p>
          </a-comment>
        </a-comment>
      </div>
      <p v-else style="textalign: center">暂无数据</p>
      <a-modal
        title="回复"
        v-model="visible"
        @ok="handleReplyOk"
        @cancel="handleReplyCancel"
        okText="确认"
        cancelText="取消"
      >
        <a-form :form="form3">
          <a-form-item>
            <a-textarea
              :rows="4"
              :value="value"
              v-decorator="[
                'comment',
                { rules: [{ required: true, message: '请输入评论!' }] },
              ]"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import myMixin from "./mixin";
import oss from "ali-oss";
import { SoftlistsModel } from "../../../models/softlists";
import { VersionsModel } from "../../../models/version";
import { CommentModel } from "../../../models/comment";
import Pagination from "../../../components/Pagination";
import upload from "../../../utils/oss";
import E from "wangeditor";
const softlistsModel = new SoftlistsModel();
const versionsModel = new VersionsModel();
const commentModel = new CommentModel();
import formatTime from "../../../utils/formatTime";
import { mapGetters } from "vuex";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "操作系统",
    dataIndex: "system",
    key: "system",
  },
  {
    title: "系统进制",
    dataIndex: "bit",
    key: "bit",
    scopedSlots: {
      customRender: "bit",
    },
  },
  {
    title: "文件名",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "软件大小",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "上传作者",
    dataIndex: "author",
    key: "author",
  },
  {
    title: "更新日期",
    dataIndex: "updated_at",
    key: "updated_at",
    scopedSlots: { customRender: "updated_at" },
  },
  {
    title: "下载量",
    dataIndex: "download_num",
    key: "download_num",
  },
  {
    title: "操作",
    dataIndex: "",
    key: "action",
    scopedSlots: {
      customRender: "action",
    },
  },
];

var system = [
  {
    name: "Windows",
    value: "windows",
  },
  {
    name: "Mac OSX",
    value: "macosx",
  },
  {
    name: "Linux",
    value: "linux",
  },
  {
    name: "IOS",
    value: "ios",
  },
  {
    name: "Android",
    value: "android",
  },
  {
    name: "Ubuntu",
    value: "ubuntu",
  },
];

export default {
  mixins: [myMixin],

  data() {
    return {
      columns,
      data: [],
      comments: [],
      system,
      editor1Content: "",
      editor2Content: "",
      loading: false,
      flag: "",
      editId: "",
      visible: false,
      value: "",
      introContent: "",
      exContent: "",
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      form2: this.$form.createForm(this, { name: "coordinated" }),
      form3: this.$form.createForm(this, { name: "coordinated" }),
      total: 0,
      ruleForm: {
        name: "",
        version: "",
        description: "",
        auth_type: undefined,
        main_app_id: undefined,
        first_cate_id: undefined,
        second_cate_id: undefined,
        label: "",
        icon: "",
        f_icon: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入软件名称！", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入软件简要描述！", trigger: "blur" },
        ],
        version: [
          { required: true, message: "请输入版本号！", trigger: "blur" },
        ],
        auth_type: [
          {
            required: true,
            message: "请选择免费或者付费软件！",
            trigger: "change",
          },
        ],
      },
      fileList: [],
      unChange: true,
      ekey: 0,
      editor1: null,
      editor2: null,
      preForm: {}, //深拷贝对象
      reply: {},
      title: "",
      zipUrl: "",
      isDisabled: true,
      progress: 0,
      recordableType: 'App'
    };
  },

  computed: {
    ...mapGetters(["id"]),
  },

  created() {
    this.querySoftList("", "", "", "", "", "", this.$route.params.id, 1);
    this.queryCommentList("", this.$route.params.id, this.recordableType, 1);
  },

  mounted() {
    setTimeout(() => {
      this.editor1 = new E(this.$refs.editorE1);
      this.editor1.customConfig.onchange = (html) => {
        this.editor1Content = html;
      };
      this.editor1.customConfig.customUploadImg = function (
        resultFiles,
        insertImgFn
      ) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        for (let i = 0; i < resultFiles.length; i++) {
          upload(resultFiles[i], "softmall-images", {}).then((res) => {
            if (res) {
              insertImgFn(`https://softmall-images.oss-cn-qingdao.aliyuncs.com/${res}`)
            }
          });
        }
      };
      this.editor1.create();
      this.editor1.txt.html(this.introContent);
      this.editor2 = new E(this.$refs.editorE2);
      this.editor2.customConfig.onchange = (html) => {
        this.editor2Content = html;
      };
      this.editor2.customConfig.customUploadImg = function (
        resultFiles,
        insertImgFn
      ) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        for (let i = 0; i < resultFiles.length; i++) {
          upload(resultFiles[i], "softmall-images", {}).then((res) => {
            if (res) {
              insertImgFn(`https://softmall-images.oss-cn-qingdao.aliyuncs.com/${res}`);
            }
          });
        }
      };
      this.editor2.create();
      this.editor2.txt.html(this.exContent);
    }, 1000);
  },

  methods: {
    handleShow(text) {
      let url = "";
      if (text == "introduction") {
        url =
          process.env.VUE_APP_PORT +
          "/apps/" +
          this.$route.params.id +
          "?type=all" +
          "#cat";
      } else if (text == "instruction") {
        url =
          process.env.VUE_APP_PORT +
          "/apps/" +
          this.$route.params.id +
          "?type=all" +
          "/extro#cat";
      } else if (text == "comment") {
        url =
          process.env.VUE_APP_PORT +
          "/apps/" +
          this.$route.params.id +
          "?type=all" +
          "/comment#cat";
      } else {
        url =
          process.env.VUE_APP_PORT +
          "/apps/" +
          this.$route.params.id +
          "?type=all";
      }
      location.href = url;
    },

    callback(key) {
      this.ekey = key;
      if (key == 2) {
        this.queryVersionList(this.$route.params.id, 1);
      }
    },

    showModal(flag) {
      this.progress = 0;
      this.visible = true;
      this.flag = flag;
      this.fileList = [];
      this.title = "新增";
    },

    showReviewModal() {
      this.visible = true;
    },

    handleById(record, flag) {
      this.visible = true;
      this.isDisabled = false;
      this.progress = 100;
      this.title = "编辑";
      this.flag = flag;
      this.editId = record.id;
      this.zipUrl = record.url;
      this.fileList = [
        {
          uid: "1",
          name: record.name,
          status: "done",
          url: record.url,
        },
      ];
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          system: record.system,
          author: record.author,
          bit: record.bit,
        });
      });
    },

    handleChange(page) {
      this.queryVersionList(this.$route.params.id, page);
    },

    handleCancel() {
      this.form2.resetFields();
      this.progress = 0;
    },

    handleReplyCancel() {
      this.form3.resetFields();
    },

    handleClear() {
      this.$refs.ruleForm.resetFields();
      this.imageUrl1 = "";
      this.imageUrl2 = "";
    },

    handleIntroSub() {
      softlistsModel
        .update({ introduction: this.editor1Content }, this.$route.params.id)
        .then((res) => {
          if (res.code == 0) {
            this.$notification.open({
              message: "提示",
              description: res.message,
              duration: 2,
            });
            this.querySoftList(
              "",
              "",
              "",
              "",
              "",
              "",
              this.$route.params.id,
              1
            );
          }
        });
    },

    handleExSub() {
      softlistsModel
        .update(
          { install_instruction: this.editor2Content },
          this.$route.params.id
        )
        .then((res) => {
          if (res.code == 0) {
            this.$notification.open({
              message: "提示",
              description: res.message,
              duration: 2,
            });
            this.querySoftList(
              "",
              "",
              "",
              "",
              "",
              "",
              this.$route.params.id,
              1
            );
          }
        });
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          values.url = this.zipUrl;
          values.name = this.fileList[0].name;
          values.size = this.fileList[0].size;
          values.app_id = this.$route.params.id;
          if (this.flag == "new") {
            versionsModel.create(values).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.queryVersionList(this.$route.params.id, 1);
              }
            });
          } else {
            versionsModel.update(values, this.editId).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.queryVersionList(this.$route.params.id, 1);
              }
            });
          }
          this.form2.resetFields();
        }
      });
    },

    handleReplyOk(e) {
      e.preventDefault();
      this.form3.validateFields((err, values) => {
        if (!err) {
          values.recordable_type = this.recordableType
          values.recordable_id = this.reply.recordable_id
          values.parent_id = this.reply.id
          values.user_id = 0
          commentModel.create(values).then((res) => {
            if (res.code == 0) {
              this.visible = false;
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryCommentList("", this.$route.params.id, this.recordableType, 1);
              this.form3.resetFields();
            }
          });
        }
      });
    },

    handleReply(item) {
      this.visible = true;
      this.reply = item;
    },

    handleEdit(record, flag) {
      console.log(record, flag);
    },

    handleDelete(record) {
      const confirmText = ["确定删除当前版本吗？", "删除后将不可恢复"];
      const newDatas = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newDatas.push(h("p", null, confirmText[i]));
      }
      this.$confirm({
        content: h("div", null, newDatas),
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          versionsModel.destroy(record.id).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryVersionList(this.$route.params.id, 1);
              const client = new oss({
                accessKeyId: "LTAIbW3TXatTSBZH",
                accessKeySecret: "nHnSsGlWx8CKr92sQ0eJcuyLbmtxUW",
                region: "oss-cn-qingdao", //
                bucket: "softmall-apps",
              });
              try {
                let result = client.delete(
                  record.url.split(
                    "softmall-apps.oss-cn-qingdao.aliyuncs.com/"
                  )[1]
                );
                console.log(result);
              } catch (e) {
                console.log(e);
              }
            }
          });
        },
        onCancel() {},
      });
    },

    handleReplyDelete(id) {
      const confirmText = ["确定删除当前评论内容吗？", "删除后将不可恢复"];
      const newDatas = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newDatas.push(h("p", null, confirmText[i]));
      }
      this.$confirm({
        content: h("div", null, newDatas),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          commentModel.destroy(id).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryCommentList("", this.$route.params.id, this.recordableType, 1);
            }
          });
        },
        onCancel() {},
      });
    },

    handleFileChange(info) {
      console.log(info);
      upload(this.fileList[0], "softmall-apps", {
        progress: (p) => {
          this.progress = Number(Number(p.toFixed(3) * 100).toFixed(1));
        },
        headers: {
          "Content-Disposition":
            "attachment; filename=" + this.fileList[0].name + "",
        },
      }).then((res) => {
        this.zipUrl = res;
        this.isDisabled = false;
      });
    },

    beforeUpload3(file) {
      this.isDisabled = true;
      let fileList = [
        {
          uid: "1",
          name: "",
          status: "",
          url: "",
        },
      ];
      fileList = fileList.slice(-1);
      fileList = [file];
      this.fileList = fileList;
      console.log(this.fileList, 3333);
      return false;
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.preForm = JSON.parse(JSON.stringify(this.ruleForm));
          this.preForm.main_app_id = this.preForm.main_app_id
            ? this.preForm.main_app_id
            : 0;
          this.preForm.first_cate_id = this.preForm.first_cate_id
            ? this.preForm.first_cate_id
            : 0;
          this.preForm.second_cate_id = this.preForm.second_cate_id
            ? this.preForm.second_cate_id
            : 0;
          this.preForm.icon = this.imageUrl1 ? this.imageUrl1 : "";
          this.preForm.f_icon = this.imageUrl2 ? this.imageUrl2 : "";
          this.loading = true;
          softlistsModel
            .update(this.preForm, this.$route.params.id)
            .then((res) => {
              if (res.code == 0) {
                this.loading = false;
                this.unChange = true;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
              }
            });
        } else {
          return false;
        }
      });
    },

    querySoftList(
      key,
      first_cate,
      second_cate,
      recommend,
      is_free,
      point,
      id,
      page
    ) {
      this.loading = true;
      softlistsModel
        .index(
          key,
          first_cate,
          second_cate,
          recommend,
          is_free,
          point,
          id,
          page
        )
        .then((res) => {
          if (res.code == 0) {
            this.imageUrl1 = res.result.icon;
            this.imageUrl2 = res.result.f_icon;
            this.introContent = res.result.introduction;
            this.exContent = res.result.install_instruction;
            this.ruleForm = res.result;
            this.ruleForm.main_app_id = res.result.main_app_id
              ? res.result.main_app_id
              : undefined;
            this.ruleForm.first_cate_id = res.result.first_cate_id
              ? res.result.first_cate_id
              : undefined;
            this.ruleForm.second_cate_id = res.result.second_cate_id
              ? res.result.second_cate_id
              : undefined;
            this.preForm = JSON.parse(JSON.stringify(res.result));
            this.handleSelectChange2(this.ruleForm.first_cate_id);
            this.loading = false;
          }
        });
    },

    queryVersionList(id, page) {
      this.loading = true;
      versionsModel.index(id, page).then((res) => {
        if (res.code == 0) {
          res.result.map((item, index) => {
            item.key = index;
            item.updated_at = formatTime(item.updated_at);
            if (item.size >= 1024 && item.size < 1024 * 1024) {
              item.size = (item.size / 1024).toFixed(2) + "KB";
            } else if (
              item.size > 1024 * 1024 &&
              item.size < 1024 * 1024 * 1024
            ) {
              item.size = (item.size / (1024 * 1024)).toFixed(2) + "MB";
            } else {
              item.size = (item.size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
            }
            item.download_num = item.download_num ? item.download_num : 0;
            return item;
          });
          this.data = res.result;
          this.loading = false;
        }
      });
    },

    queryCommentList(key, id, recordableType, page) {
      this.loading = true;
      const data = {
        key: key,
        recordable_id: id,
        recordable_type: recordableType,
        page: page
      }
      commentModel.index(data).then((res) => {
        if (res.code == 0) {
          res.result.map((item, index) => {
            item.key = index;
            if (item.user) {
              item.user_id = item.user.user_name;
            } else {
              item.user_id = "xxx";
            }
            item.created_at = formatTime(item.created_at);
            item.children.map((item, index) => {
              item.key = index;
              item.admin_id = item.admin.admin_name;
              item.created_at = formatTime(item.created_at);
              return item;
            });
            return item;
          });
          this.comments = res.result;
          this.loading = false;
        }
      });
    },
  },

  watch: {
    ruleForm: {
      handler: function (newVal) {
        var $this = this;
        for (let i in $this.ruleForm) {
          if (newVal[i] != $this.preForm[i]) {
            $this.unChange = false;
            break;
          } else {
            $this.unChange = true;
          }
        }
      },
      deep: true,
    },
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss">
.second_cate {
  width: 50%;
  .ant-col {
    float: right;
  }
}

.first_cate {
  width: 50%;
  position: relative;
  .ant-col-6 {
    width: 50%;
  }

  .ant-col-18 {
    width: 73%;
    right: -23%;
    position: absolute;
  }
}
</style>

<style lang="scss" scoped>
.ant-upload-picture-card-wrapper {
  width: 110px;
  margin-right: 40px;
}

.avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
  float: left;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.clearfix {
  clear: both;
}

.upload-word span {
  width: 110px;
  display: inline-block;
  text-align: center;
  margin-right: 40px;
  font-size: 13px;
  color: #333;
}

.ant-form-item {
  margin-bottom: 10px;
}

.sl-select {
  width: 45% !important;
}

.f_icon,
.icon {
  width: 64px;
}

.first_cate,
.second_cate {
  float: left;
  z-index: 100;
}

.page-editor {
  position: relative;
  background: #fff;
  height: 100%;
}

.ant-comment-content-author-name span {
  color: #ccc;
  margin-left: 20px;
}

.ant-comment-inner {
  padding: 0;
}

.ant-upload-list-item-card-actions .anticon {
  display: none;
}
</style>