import {HTTP} from '../utils/axios'

class VersionsModel extends HTTP {
  /*首页渲染*/
  index(id, page) {
      return this.request({
          url: 'board/app_versions',
          method: "GET",
          data: {
            id: id,
            page: page
          }
      })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/app_versions",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/app_versions/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy(id) {
    return this.request({
        url: 'board/app_versions/' + id,
        method: "DELETE"
    })
  }
}

export {VersionsModel}